<template>
  <div class="site-list-wrap">
    <div :class="{'site-list': true, 'activeSelect':pickUpId===item.id}" v-for="item in pickSiteList" :key="item.id" @click="selectItem(item)">
      <div class="site-img">
        <img :src="pickUpId===item.id ? require('../../images/gouxuan2.png') : require('../../images/gouxuan1.png')" />
      </div>
      <div class="site-right">
        <div class="site-name">
          <span>{{item.deliveryName}}</span>
        </div>
        <div class="site-address-wrap">
          <span class="site-address">{{item.deliveryAddress}}</span>
        </div>
        <div class="site-phone">
          <span>{{item.phone}}</span>
        </div>
        <!-- <div class="distance-map">
          <span class="site-dis">距离{{item.distanceDesc}}</span>
        </div> -->
      </div>
    </div>
    <div class="more" ref="more">&nbsp;</div>
    <div class="contentno" v-if="pickSiteList.length<=0">
      <span>暂无自提点</span>
    </div>
  </div>
  <Toast v-if="show" :message="toastMessage"/>
</template>
<script>
import qs from 'qs'
import { reactive, toRefs, onMounted, getCurrentInstance } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import { post, ajaxParam, config } from '../../units/request.js'
import Toast, { useToastEffect } from '../../components/Toast.vue'
// import axios from 'axios'
export default {
  name: 'PickSite',
  components: {
    Toast
  },
  setup () {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const { show, toastMessage, showToast } = useToastEffect()
    const data = reactive({ pickSiteList: [], pickUpId: '', lat: '', lng: '', isLastPage: 0, num: 1 })
    const { proxy } = getCurrentInstance()
    onMounted(() => {
      const winHeight = document.documentElement.clientHeight
      var timer = null
      window.addEventListener('scroll', (res) => {
        // 获取元素距离顶部相对位置
        if (proxy.$refs.more) {
          const scrollHeight = proxy.$refs.more.getBoundingClientRect().top
          if (timer) {
            clearTimeout(timer)
          }
          timer = setTimeout(() => {
            if (winHeight > scrollHeight) {
              // 不能加载多次 防抖
              if (data.isLastPage !== 1) {
                data.num += 1
                getpickSite(data.num)
              } else {
              }
            }
          }, 300)
        }
      })
    })
    /* const getlocation = async () => {
      const res = await axios.get('https://apis.map.qq.com/ws/location/v1/ip', {
        params: {
          key: 'KXDBZ-BFXHQ-QE65Q-GBCDD-BYDH2-FTB2Q'
        }
      })
      data.lat = res.data.result.location.lat
      data.lng = res.data.result.location.lng
    } */
    const getpickSite = async (num) => {
      const param = {
        pageNum: num,
        pageSize: 6,
        shopId: config.shopId,
        lat: data.lat,
        lng: data.lng
      }
      ajaxParam(param)
      const res = await post(
        'v1/selfDelivery/cityShopSelfDeliveryPage.json',
        qs.stringify(param)
      )
      data.pickSiteList.push(...res.data.list)
    }
    getpickSite(1)
    const selectItem = (item) => {
      data.pickUpId = item.id
      store.commit('selectAddress', item)
      showToast('信息发生变更，请确认')
      setTimeout(res => {
        if (route.query.confirmType === '1') {
          router.push({
            name: 'ConfirmOrder',
            params: {
              type: 'picksite'
            }
          })
        } else {
          router.push({
            name: 'Order',
            params: {
              type: 'picksite'
            }
          })
        }
      }, 1000)
    }
    const { pickSiteList, pickUpId, more } = toRefs(data)
    return { pickSiteList, pickUpId, selectItem, show, toastMessage, showToast, more }
  }
}
</script>
<style lang="scss" scoped>
.site-list-wrap {
  background: #fff;
}
.site-list {
  margin: 0 0.15rem;
  padding: 0.1rem;
  border-bottom: 1px solid #f3f3f3;
  box-sizing: border-box;
  color: #999;
  position: relative;
  font-size: 0.14rem;
}
.activeSelect {
  background: rgba(82, 218, 95, 0.1);
}
.site-img {
  position: absolute;
  left: 0.15rem;
  top: 50%;
  margin-top: -0.1rem;
}
.site-img img {
  width: 0.16rem;
  height: 0.16rem;
  margin-right: 0.2rem;
}
.site-right {
  margin-left: 0.46rem;
  justify-content: center;
  line-height: 0.26rem;
}
.site-name {
  display: flex;
  justify-content: space-between;
  font-size: 0.16rem;
  color: #333;
}
.site-dis {
  display: inline-block;
  color: #52da5f;
}
.site-map {
  display: flex;
  color: #999;
  margin-left: 0.2rem;
}
.site-address-wrap {
  display: flex;
  justify-content: space-between;
  color: #666;
}
.site-address {
  color: #999;
}
.site-no {
  padding-top: 0.8rem;
  text-align: center;
}
.more{
  line-height: .24rem;
  text-align: center;
}
.contentno{
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background:#fff;
  text-align: center;
}
.contentno span {
  position: relative;
  top: 40%;
  font-size: .16rem;
}
</style>
